import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';

barnManagerModule
  .factory('Notification', ['NotificationTypes', 'dateTimeUpgraded', (NotificationTypes, dateTimeUpgraded) => {
    function Notification(data) {
      angular.extend(this, angular.copy(data));
      this.shortDate = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted(this.created, 'DATE');
      this.date = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted(this.created);
      this.time = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted(this.created, 'HOURS');
      this.value = NotificationTypes[this.type].value;
    }

    Notification.prototype = {
      entity: function() {
        return NotificationTypes[this.type].entity(this.params);
      },
      entityUrl: function() {
        const entity = this.entity();
        return NotificationTypes[this.type].entityUrl(entity, this.params);
      }
    };

    return Notification;
  }]);
