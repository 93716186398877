import {barnManagerModule} from '../../../index.module';
import {isEmpty, map, reject, values} from 'lodash';
import {copy} from 'angular';

barnManagerModule.controller('EventFormController', EventFormController);

EventFormController.$inject = [
  '$q',
  '$state',
  '$stateParams',
  '$scope',
  'backLinkHistory',
  'responseHandler',
  'barnStorage',
  'rEvents',
  'vendorRepository',
  'rUser',
  'treatmentRepository',
  'titlesService',
  'EventTypes',
  'RepeatingFrequencies',
  'Vendor',
  'eventMapper',
  'NOTE_LENGTH_LIMIT',
  '$location',
  '$window',
  'sessionStorageService',
  'horseRepository',
  'rContacts',
  'MessageFrames',
  'colorsRepository',
  'hasPermission',
  'listRepository'
];

function EventFormController(
  $q,
  $state,
  $stateParams,
  $scope,
  backLinkHistory,
  responseHandler,
  barnStorage,
  rEvents,
  vendorRepository,
  rUser,
  treatmentRepository,
  titlesService,
  EventTypes,
  RepeatingFrequencies,
  Vendor,
  eventMapper,
  NOTE_LENGTH_LIMIT,
  $location,
  $window,
  sessionStorageService,
  horseRepository,
  rContacts,
  MessageFrames,
  colorsRepository,
  hasPermission,
  listRepository
) {
  let vm = this, barn;
  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasVendorsFullPermission = hasPermission('vendors:full');
  vm.noteLengthLimit = NOTE_LENGTH_LIMIT;
  vm.submitAttempt = false;
  vm.saving = false;
  vm.done = true;
  vm.horseId = $state.params.horseId;
  vm.instance = $state.params.instance;
  vm.thisAndFollowing = $state.params.thisAndFollowing;
  vm.editMode =
    $state.current.name === 'eventEdit' ||
    $state.current.name === 'eventHorseEdit';
  vm.calendarOpen = false;
  vm.calendarOpenEnds = false;
  vm.calendarOpenEndOn = false;
  vm.selectedWeekdays = [];
  vm.weekdaysHidden = '';     // hotfix: weedays validation field
  vm.repeats = {};
  vm.reminders = [];
  vm.allUsers = [];
  vm.treatments = {};
  vm.horses = [];
  vm.contacts = [];
  vm.assignments = [];
  vm.eventTypes = getEventTypes();
  vm.errors = {};
  vm.allVendors = [];

  vm.hstep = 1;
  vm.mstep = 15;

  vm.options = {
    hstep: [1, 2, 3],
    mstep: [1, 5, 10, 15, 25, 30]
  };

  vm.ismeridian = true;
  vm.loading = false;

  vm.colors = [];

  vm.save = save;
  vm.openCalendar = openCalendar;
  vm.addReminder = addReminder;
  vm.getVendorLabel = titlesService.getVendorLabel;
  vm.repeatingFrequencies = RepeatingFrequencies;
  vm.onTitleChange = onTitleChange;
  vm.onWeekdaysChange = onWeekdaysChange;
  vm.onHorsesChange = onHorsesChange;
  vm.onColorChange = onColorChange;
  vm.onColorUpdated = onColorUpdated;
  vm.onColorsChanged = onColorsChanged;
  vm.onAddAssignments = onAddAssignments;
  vm.onTreatmentsChange = onTreatmentsChange;
  vm.onArchiveTreatment = onArchiveTreatment;
  vm.onRestoreTreatment = onRestoreTreatment;
  vm.onToggleArchiveVendor = onToggleArchiveVendor;
  vm.onVendorChange = onVendorChange;
  vm.initiateSaveVendor = initiateSaveVendor;
  vm.onAddVendorSuccess = onAddVendorSuccess;
  vm.getVendorType = getVendorType;
  vm.onMembersChange = onMembersChange;
  vm.showAutoGenerateRecord = false;
  vm.allLists = [];
  vm.listChanged = listChanged;
  vm.isListSelected = false;
  vm.list = []
  vm.listDataWithCalendarTaskList = false;
  vm.id = null;

  $scope.isFormVisible = false;

  initializeEvent();

  $scope.$watch('vm.event.types.value', function (newVal, oldVal) {
    updateAutoGenerateRecordVisibility(newVal);
  });

  function updateAutoGenerateRecordVisibility(eventType) {
    const excludeEventTypes = ["LESSON", "SHOW"];
    vm.showAutoGenerateRecord = eventType && !excludeEventTypes.includes(eventType);
    if (!vm.showAutoGenerateRecord && vm.event && eventType) {
      vm.event.createRecord = false;
    }
  }

  function onMembersChange(membersModel) {
    vm.event.memberIds = membersModel.memberIds;
    vm.event.allTeamMembersNotifications = membersModel.allTeamMembersNotifications;
  }

  function listChanged(selected) {
    if (selected) {
      const found = vm.allLists.find(item => item.id === selected.id);
      vm.isListSelected = true;
      vm.id = found.id;
      vm.list = found;
    } else {
      vm.isListSelected = false;
      vm.id = null;
      vm.list = null;
    }
  }


  function onHorsesChange(ids) {
    vm.event.horseIds = ids;
  }

  function onColorChange(item) {
    vm.event.colorUuid = item ? item.uuid : null;
    vm.event.color = item ? item.color : null;
  }

  function onColorUpdated(color) {
    if (vm.event.colorUuid === color.uuid) {
      vm.event.color = color.color;
    }
  }

  function onColorsChanged(colors) {
    vm.colors = [...colors];
  }

  function onAddAssignments(selectedAssignments) {
    const horseId = selectedAssignments.horse.id;
    if (!vm.event.horseIds.find(id => id === horseId)) {
      const ids = [...vm.event.horseIds, horseId];
      vm.onHorsesChange(ids);
    }
  }

  function openCalendar(calendar) {
    if (calendar === 'date') {
      vm.calendarOpen = true;
    }
    if (calendar === 'EndOn') {
      vm.calendarOpenEndOn = true;
    }
    if (calendar === 'dateEnds') {
      vm.calendarOpenEnds = true;
    }
  }

  function addReminder() {
    vm.reminders.push({});
  }

  function goToCalendar() {
    vm.done = true;
    responseHandler.successOnSave('Event', vm.editMode);
    goBack();
  }

  function goBack() {
    backLinkHistory.goBack();
  }

  function goBackToNewInstance(oldEvent, newEvent) {
    backLinkHistory.getBackLink().then(result => {
      if (result.external) {
        sessionStorageService.setMainBackUrl(null);
        $window.location.href = '/invoicing' + result.url;
      } else {
        const getSearchPattern = (event, segment = '?') => {
          return `${event.id}${segment}instance=${event.dateDate}`;
        };

        const links = backLinkHistory.getLinks();
        const last2Links = links.slice(links.length - 2);
        last2Links.forEach(link => {
          const linkNames = ['eventDetails', 'eventEdit', 'eventHorseDetails', 'eventHorseEdit'];
          if (linkNames.includes(link.name) && (link.url.search(getSearchPattern(oldEvent)) || link.url.search(getSearchPattern(oldEvent, '/edit?')))) {
            backLinkHistory.popLink();
          }
        });

        let url = result.url;
        if (url.search(getSearchPattern(oldEvent))) {
          url = url.replace(getSearchPattern(oldEvent), getSearchPattern(newEvent));
        } else if (url.search(getSearchPattern(oldEvent, '/edit?'))) {
          url = url.replace(getSearchPattern(oldEvent, '/edit?'), getSearchPattern(newEvent, '/edit?'));
        }

        $location.url(url);
      }
    });
  }

  function onTitleChange() {
    vm.event.customTitle = !isEmpty(vm.event.title);
  }

  function onWeekdaysChange(selectedDays) {
    vm.selectedWeekdays = selectedDays;
    checkWeekdaysChange();
  }

  // hotfix: weekdays validation field
  function checkWeekdaysChange() {
    vm.weekdaysHidden = vm.selectedWeekdays.length > 0 ? 'filled' : '';
  }

  function updateEvent(event) {
    let updatePromise;
    if (vm.instance && vm.thisAndFollowing) {
      updatePromise = rEvents.updateThisAndAllFollowingInstances({
        eventId: event.id,
        instance: vm.instance
      }, event).$promise;
    } else if (vm.instance) {
      updatePromise = rEvents.updateSingle({eventId: event.id, instance: vm.instance}, event).$promise;
    } else {
      updatePromise = rEvents.update({eventId: event.id}, event).$promise;
    }
    updatePromise.then(response => {
      responseHandler.successOnSave('Event', true);
      if (response.dateDate !== vm.instance || response.id !== event.id) {
        goBackToNewInstance(vm.event, response);
      } else {
        goBack();
      }
    }).catch(errorHandler).finally(() => vm.saving = false);
  }

  function createEvent(event) {
    return rEvents.save(event).$promise
      .then(function (response) {
        goToCalendar();
        return response;
      })
      .catch(errorHandler)
      .finally(() => vm.saving = false);
  }

  function getEventTypes() {
    if (vm.hasAdminFullPermission) {
      return reject(values(EventTypes), EventTypes.NONE);
    }

    if (hasPermission('events:full') && hasPermission('lessons:full')) {
      return [EventTypes.VET, EventTypes.DENTIST, EventTypes.FARRIER, EventTypes.DEWORMING, EventTypes.MEDICATION, EventTypes.VACCINATION, EventTypes.SHOW, EventTypes.LESSON, EventTypes.OTHER];
    }
    if (hasPermission('events:full')) {
      return [EventTypes.VET, EventTypes.DENTIST, EventTypes.FARRIER, EventTypes.DEWORMING, EventTypes.MEDICATION, EventTypes.VACCINATION, EventTypes.SHOW, EventTypes.OTHER];
    }
    if (hasPermission('lessons:full')) {
      return [EventTypes.LESSON];
    }
  }

  function save(form) {
    vm.submitAttempt = true;
    vm.errors = {};
    const formControls = form.$error.required;
    const ignoredFields = ['checkListForm'];
    let otherInvalid = false;
    if (formControls?.length) {
      for (let control of formControls) {
        if (!ignoredFields.includes(control.$name)) {
          otherInvalid = true;
          break;
        }
      }
    }
    const isValid = !otherInvalid;

    if (!isValid) {
      return;
    }
    vm.saving = true;
    vm.done = false;

    vm.event.enforceTitle();

    const payloadEvent = eventMapper.eventToPayload(vm.event, barnStorage.getEnv().timeZone, vm.selectedWeekdays, vm.repeatedEventHasEndDate, vm.assignments, vm.reminders);

    if (vm.editMode) {
      updateEvent(payloadEvent);
      if (vm.list && Object.keys(vm.list).length > 0) {
        if (vm.id) {
          vm.list.items.forEach((item, index) => item.pos = index);
          listRepository.update(vm.id, {
            ...vm.list,
            barnListCategory: "CALENDAR_TASK_LIST",
            eventId: payloadEvent.id,
            eventDate: payloadEvent.dateDate,
          })
            .catch(errorHandler);
        } else {
          listRepository.add({
            barnListCategory: "CALENDAR_TASK_LIST",
            eventId: payloadEvent.id,
            eventDate: payloadEvent.dateDate,
            ...omitIdFromList(vm.list, payloadEvent.dateDate)
          }).then(function (list) {
          }).then().catch(errorHandler);
        }
      } else if (!vm.list && vm.existingList) {
        listRepository.update(vm.existingList.id, {
          ...vm.existingList,
          barnListCategory: "CHECK_LIST",
          eventId: null,
          eventDate: null
        })
          .catch(errorHandler);
      }
    } else {
      createEvent(payloadEvent).then((eventResponse) => {
        if (this.isListSelected || $scope.isFormVisible) {
          vm.submitAttempt = true;
          if (!isValid) {
            return;
          }
          if (vm.id) {
            vm.list.items.forEach((item, index) => {
              item.pos = index;
              item.dueOn = item.dueOn ? item.dueOn : eventResponse.dateDate;
            });
            listRepository.update(vm.id, {
              ... vm.list,
              barnListCategory: "CALENDAR_TASK_LIST",
              eventId: eventResponse.id,
              eventDate: eventResponse.dateDate
            })
              .catch(errorHandler);
          } else {
            listRepository.add({
              barnListCategory: "CALENDAR_TASK_LIST",
              eventId: eventResponse.id,
              eventDate: eventResponse.dateDate,
              ...omitIdFromList(vm.list, eventResponse.dateDate)
            }).then(function (list) {
            }).then().catch(errorHandler);

          }
        }
      });
    }
  }

  function omitIdFromList(list, date) {
    if (list.items) {
      list.items.forEach((item, index) => {
        item.pos = index;
        item.dueOn = item.dueOn ? item.dueOn : date;
      });
    }
    const {id, ...rest} = list;
    return rest;
  }

  function loadTreatments() {
    return treatmentRepository.all().then(function (treatments) {
      const treatmentsTypes = [EventTypes.DEWORMING.value, EventTypes.MEDICATION.value, EventTypes.VACCINATION.value];
      treatmentsTypes.forEach(type => vm.treatments[type] = {items: []});
      treatments.forEach(item => vm.treatments[item.type].items.push(item));
      return treatments;
    });
  }

  function loadVendors() {
    return vendorRepository.allIncludingArchived().then(function (vendors) {
      vm.allVendors = map(vendors.records, (vendorData) => new Vendor(vendorData))
        .sort((a, b) => sortAlphabetically(a.fullName(), b.fullName()));
    });
  }

  function loadEvent(currentEventId) {
    if (vm.instance) {
      return rEvents.getInstance({
        eventId: currentEventId,
        instance: $stateParams.instance
      }).$promise;
    } else {
      return rEvents.get({
        eventId: currentEventId
      }).$promise;
    }
  }

  function eventFromPayload(event) {
    let cloned = copy(event);

    cloned = eventMapper.eventFromPayload(cloned, barnStorage.getEnv().timeZone, vm.allVendors, true);

    if (cloned.endOn) {
      vm.repeatedEventHasEndDate = '1';
    }

    if (cloned.repeat) {
      vm.selectedWeekdays = map(cloned.repeats, function (repeat) {
        return repeat.repeatWeekDay;
      });
      checkWeekdaysChange();
    }

    if (cloned.assignments && cloned.assignments.length > 0) {
      vm.assignments = cloned.assignments;
    }

    vm.event = cloned;
  }

  function loadAllUsers() {
    rUser.search({
      tenantEnvironmentId: barn.id,
      archived: false,
      pagesize: 999
    }).$promise.then(function (response) {
      vm.allUsers = response.records;
    });
  }

  function loadAllList() {
    listRepository.search({
      tenantEnvironmentId: barn.id,
      pagesize: 9999
    }, true)
      .then(function (response) {
        vm.allLists = response.records.filter(list =>
          list.barnListCategory === 'CHECK_LIST' || list.barnListCategory === 'CALENDAR_TASK_LIST'
        );
      })
  }

  function loadAllHorses() {
    horseRepository.all().then(function (horses) {
      vm.horses = horses;
    });
  }

  function loadColors() {
    colorsRepository.all().then(function (colors) {
      vm.colors = colors.sort((a, b) => sortAlphabetically(a.name, b.name));
    });
  }

  function loadAllContacts() {
    const queryParams = {
      tenantEnvironmentId: barn.id,
      pagesize: 10000,
      archived: 0
    };

    rContacts.query(queryParams, function (response) {
      vm.contacts = response.records;
    });
  }

  function initializeEvent() {
    vm.loading = true;
    let currentEventId = $stateParams.id;

    barn = barnStorage.getEnv();

    if ($state.current.name === 'eventHorseEdit') {
      currentEventId = $stateParams.eventId;
    }
    watchVendorsForCurrentEventTypeAndEventTypeChanges();
    watchTreatmentsAndEventTypeChanges();
    watchShowAddVendorChange();

    $q.all([loadVendors(), loadTreatments()]).then(function () {
      if (vm.editMode) {
        loadEvent(currentEventId).then(function (event) {
          vm.loading = false;
          vm.reminders = event.reminders;
          eventFromPayload(event);
          backLinkHistory.pushLink(vm.event.title);
          listRepository.getEventWithExistingCalendarTaskList(event.id, event.dateDate)
            .then(function (data) {
              if (data) {
                vm.id = data?.id;
                vm.list = data;
                vm.existingList = data;
              }
                if (vm.editMode && data) {
                  vm.listDataWithCalendarTaskList = true
                  vm.isListSelected = true;
                }
              }
            )
        }).catch(error => {
          vm.loading = false;
          vm.error = error?.data?.message || error?.statusText;
          return errorHandler(error);
        });
      } else {
        vm.listDataWithCalendarTaskList = false;
        vm.loading = false;
        backLinkHistory.pushLink('New event');
        vm.event = eventMapper.makeEmptyEvent(barn.id);
        if (vm.horseId) {
          vm.event.horseIds = [Number(vm.horseId)];
        }
        vm.event.date = new Date();
      }
      $scope.$watch('vm.event.date', function(newValue, oldValue) {
        if (newValue !== oldValue) {
            vm.dueOn = newValue
        }
      });
      loadAllUsers();
      loadAllContacts();
      loadAllHorses();
      loadColors();
      loadAllList();
    });
  }

  function watchTreatmentsAndEventTypeChanges() {
    $scope.$watchCollection('[vm.treatments, vm.event.types.value]', ([treatments, eventType]) => {
      if (treatments && eventType && treatments[eventType]?.items) {
        vm.filteredTreatments = treatments[eventType].items.filter(item => !item.archived);
        vm.treatmentSettingBtnAvailable = treatments[eventType].items && treatments[eventType].items.some(i => i.id);
      }
    });
  }

  function onTreatmentsChange(selected) {
    vm.event.treatments = selected.map(i => i.name || i);
    const allTreatmentNames = vm.treatments[vm.event.types.value].items.map(i => i.name);
    selected.forEach(i => {
      if (!allTreatmentNames.includes(i.name || i)) {
        vm.treatments[vm.event.types.value].items.push({...i, archived: false});
        vm.treatments[vm.event.types.value].items = vm.treatments[vm.event.types.value].items.sort((a, b) => sortAlphabetically(a.name, b.name));
      }
    });
  }

  function onArchiveTreatment(item) {
    item.loading = true;
    treatmentRepository.archive(item.id).then(function () {
      const treatment = vm.treatments[vm.event.types.value].items.find(i => i.id === item.id);
      if (treatment) {
        treatment.archived = true;
      }
      vm.treatments = {...vm.treatments};
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function onRestoreTreatment(item) {
    item.loading = true;
    treatmentRepository.restore(item.id).then(function () {
      const treatment = vm.treatments[vm.event.types.value].items.find(i => i.id === item.id);
      if (treatment) {
        treatment.archived = false;
      }
      vm.treatments = {...vm.treatments};
    }).catch(errorHandler).finally(() => item.loading = false);
  }

  function watchVendorsForCurrentEventTypeAndEventTypeChanges() {
    $scope.$watch('vm.event.types.value', () => vm.vendorsForCurrentEventType = getVendorsForCurrentEventType());
    $scope.$watch('vm.vendorsForCurrentEventType', () => vm.filteredVendors = [...vm.vendorsForCurrentEventType.filter(vendor => !vendor.archived)]);
  }

  function getVendorsForCurrentEventType() {
    const filterVendors = (vendor) => {
      if (vm.event && vm.event.types.value) {
        return getVendorType().filter(vendor);
      } else {
        return true;
      }
    };

    return [...vm.allVendors.filter(vendor => filterVendors(vendor))];
  }

  function onToggleArchiveVendor(item) {
    const vendor = vm.allVendors.find(i => i.id === item.id);
    if (vendor) {
      item.loading = true;
      const archived = vendor.archived ? 0 : 1;
      const payload = Object.assign(copy(vendor), {archived});
      vendorRepository.update(item.id, payload).then(function () {
        vendor.archived = item.archived = archived;
        vm.vendorsForCurrentEventType = [...vm.vendorsForCurrentEventType];
        console.log(vm.vendorsForCurrentEventType)
      }).catch(errorHandler).finally(() => item.loading = false);
    }
  }

  function onVendorChange(vendor) {
    vm.event.vendor = vendor;
  }

  function initiateSaveVendor() {
    $scope.$broadcast('initiateSaveVendor');
  }

  function onAddVendorSuccess(vendor) {
    vm.allVendors.push(new Vendor(vendor));
    vm.allVendors = vm.allVendors.sort((a, b) => sortAlphabetically(a.fullName(), b.fullName()));
    vm.vendorsForCurrentEventType = getVendorsForCurrentEventType();
    vm.showAddVendor = false;
    vm.showVendorConfigureModal = false;
  }

  function watchShowAddVendorChange() {
    $scope.$watch('vm.showAddVendor', () => vm.addingVendor = false);
  }

  function getVendorType() {
    const eventType = EventTypes[vm.event.types.value];
    return eventType.vendorRestriction;
  }

  function sortAlphabetically(a, b) {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
  }

  function errorHandler(error) {
    return responseHandler.processError(error, null, MessageFrames.TOP);
  }

  $scope.showForm = function ($event) {
    $event.stopPropagation();
    $event.preventDefault();
    $scope.isFormVisible = true;
    vm.isListSelected = false;
    vm.list = {};
    vm.list.title = ''
    vm.list.items = [];
    vm.id = null;
  }

  $scope.hideForm = function ($event) {
    $event.stopPropagation();
    $event.preventDefault();
    $scope.isFormVisible = false;
    vm.list = {};
    vm.list.title = ''
    vm.list.items = [];
    vm.id = null;
  }
}
