import {barnManagerModule} from '../../../index.module';
import {copy} from 'angular';
import moment from 'moment';
import {filter, find, get, includes, isNil, map, omit} from 'lodash';

barnManagerModule.controller('ContactFormController', ContactFormController);

ContactFormController.$inject = [
  'backLinkHistory',
  'barnStorage',
  'utils',
  'listsService',
  'ContactRoles',
  'responseHandler',
  'ScrollToMessageFrame',
  'horseRepository',
  'userRepository',
  'rContacts',
  'imageService',
  'rAttachments',
  '$state',
  '$q',
  'States',
  'Countries',
  'TerritorialUnits',
  'DATE_ISO_FORMAT',
  'PATTERNS',
  'titleService'
];
function ContactFormController(
  backLinkHistory,
  barnStorage,
  utils,
  listsService,
  ContactRoles,
  responseHandler,
  ScrollToMessageFrame,
  horseRepository,
  userRepository,
  rContacts,
  imageService,
  rAttachments,
  $state,
  $q,
  States,
  Countries,
  TerritorialUnits,
  DATE_ISO_FORMAT,
  PATTERNS,
  titleService
) {
  let vm = this, barn, originImage: any = {}, removedFiles = [];

  vm.noteLengthLimit = 2000;
  vm.contactHorses = [];
  vm.states = States;
  vm.countries = Countries;
  vm.TerritorialUnits = TerritorialUnits;
  vm.patterns = PATTERNS;
  vm.saving = false;
  vm.editMode = false;
  vm.attachmentsReady = false;
  vm.roles = ContactRoles;

  vm.error = false;
  vm.loaded = false;

  vm.removeHorse = removeHorse;
  vm.addHorse = addHorse;
  vm.queueDelete = queueDelete;
  vm.removeOrganization = removeOrganization;
  vm.addOrganization = addOrganization;
  vm.save = saveContact;
  vm.onHorseChange = onHorseChange;

  vm.onBillingCountrySelected = onBillingCountrySelected;
  vm.onMailingCountrySelected = onMailingCountrySelected;
  vm.lastBillingCountry = null;
  vm.lastMailingCountry = null;

  vm.avatarRemoved = false;
  vm.onAvatarRemoved = onAvatarRemoved;
  vm.avatar = null;
  vm.avatarLoaded = false;

  vm.onAvatarChanged = function(file, error) {
    vm.avatarRemoved = false;
    vm.avatarError = error;
    vm.avatar = file;
  };

  function onAvatarRemoved() {
    vm.avatarRemoved = true;
    vm.avatarError = null;
    vm.avatar = null;
  }

  function processAvatar(contact, avatar) {
    if (vm.avatarRemoved && contact.avatarId) {
      return rContacts.removeImage({ id: contact.id }).$promise;
    } else {
      return uploadImage(contact, avatar);
    }
  }

  function uploadImage(contact, image) {
    const deferred = $q.defer();
    vm.avatarError = null;

    if (!image || typeof image === 'undefined' || (originImage.name === image.name)) {
      deferred.resolve();
    } else {
      const payload = new FormData();

      payload.append('file', image);

      rContacts.saveImage({ tenantEnvironmentId: barn.id, id: contact.id }, payload).$promise.then(function(result) {
        deferred.resolve(result);
      }).catch(function(uploadError) {
        vm.avatarError = uploadError.data.error;
        deferred.reject(uploadError.data.error);
      });
    }

    return deferred.promise;
  }

  function getAvatar(contact) {
    imageService.getImage(contact.avatarId).then(function(image) {
      if (image) {
        vm.avatar = image.data;
        originImage = copy(image.data);
      }
    }).finally(() => vm.avatarLoaded = true);
  }

  function onBillingCountrySelected($item, $model) {
    if (vm.lastBillingCountry !== $model) {
      vm.contact.primaryContactBillingAddress.state = null;
      vm.lastBillingCountry = $model;
    }
  }

  function onMailingCountrySelected($item, $model) {
    if (vm.lastMailingCountry !== $model) {
      vm.contact.primaryContactMailingAddress.state = null;
      vm.lastMailingCountry = $model;
    }
  }

  function getAttachments(contactId) {
    return rAttachments.query({ modelType: 'CONTACT', modelId: contactId }).$promise;
  }

  function queueDelete(file) {
    removedFiles.push(file);
  }

  function addOrganization($event) {
    $event.preventDefault();

    vm.contactMemberships.push({});
  }

  function removeOrganization(index, $event) {
    $event.preventDefault();

    vm.contactMemberships.splice(index, 1);
  }

  function addHorse($event) {
    $event.preventDefault();

    if (!isNil(vm.selectedHorse)) {
      if (!find(vm.contactHorses, { horse: { id: vm.selectedHorse.id } })) {
        vm.contactHorses.push({
          horse: vm.selectedHorse,
          ownership: {
            isOwner: false
          }
        });
        vm.contactHorses = utils.sortByCaseInsensitive(vm.contactHorses, 'horse.name');
        setContactHorseIds();
      }
      vm.selectedHorse = null;
      vm.horsesAvailableForSelect = vm.horses.filter(horse => filterSelectedHorses(horse));
    }
  }

  function removeHorse($event, index) {
    $event.preventDefault();
    vm.contactHorses.splice(index, 1);
    setContactHorseIds();
  }

  function saveContact(form) {
    if (!form.$valid || vm.avatarError) {
      return;
    }

    vm.error = false;
    vm.saving = true;

    if (vm.contactHorses.length > 0) {
      vm.contact.horseOwnerships = map(vm.contactHorses, function(contactHorse) {
        return {
          horseId: contactHorse.horse.id
        };
      });
    } else {
      vm.contact.horseOwnerships = [];
    }

    vm.contact.memberships = filter(map(copy(vm.contactMemberships), function(m) {
      const membership = copy(m);

      if (membership.expiresOn) {
        membership.expiresOn = moment(membership.expiresOn).format(DATE_ISO_FORMAT);
      }

      return membership;
    }), function(m) {
      return Object.keys(m).length > 0;
    });

    if (vm.editMode) {
      return rContacts.update(vm.contact).$promise.then(successHandler).catch(contactErrorHandler);
    } else {
      return rContacts.save(vm.contact).$promise.then(successHandler).catch(contactErrorHandler);
    }
  }

  function successHandler(savedContact) {
    return processAvatar(savedContact, vm.avatar).then(function() {
      listsService.updateAttachments(vm.attachments, removedFiles, savedContact);
      listsService.ready(function() {
        responseHandler.successOnSave('Contact', vm.editMode);
        vm.saving = false;
        backLinkHistory.goBack();
      });
    }).catch(errorHandler);
  }

  function contactErrorHandler(error) {
    vm.saving = false;
    return errorHandler(error);
  }

  function errorHandler(error) {
    ScrollToMessageFrame();
    return responseHandler.processError(error);
  }

  function processContact(rawContact) {
    let contact = copy(rawContact);
    contact = omit(contact, ['firstName', 'lastName']);

    if (contact.memberships.length === 0 || Object.keys(contact.memberships[0]).length === 0) {
      vm.contactMemberships = [{}];
    } else {
      vm.contactMemberships = map(contact.memberships, function(org) {
        if (org.expiresOn) {
          org.expiresOn = moment(org.expiresOn).toDate();
        }
        return org;
      });
    }

    vm.contactHorses = map(contact.horseOwnerships, function(h) {
      const horse = find(vm.horses, { id: h.horseId });
      return {
        horse: horse,
        ownership: h
      };
    });
    vm.contactHorses = utils.sortByCaseInsensitive(vm.contactHorses, 'horse.name');
    setContactHorseIds();

    vm.lastBillingCountry = get(contact, 'primaryContactBillingAddress.country', null);
    vm.lastMailingCountry = get(contact, 'primaryContactMailingAddress.country', null);

    return contact;
  }

  function setContactHorseIds() {
    vm.contactHorseIds = map(vm.contactHorses, function(horse) {
      return horse.horse.id;
    });
  }

  function filterSelectedHorses(horse) {
    return !includes(vm.contactHorseIds, horse.id);
  }

  function onHorseChange(horse) {
    vm.selectedHorse = horse;
  }

  function init() {
    barn = barnStorage.getEnv();

    listsService.resetTo('contacts', 'CONTACT');
    vm.editMode = $state.current.name === 'contactEdit';

    titleService.setTitle(vm.editMode ? 'Edit Contact :: ' : 'Add Contact :: ');

    $q.all([horseRepository.allIncludingArchived(), userRepository.all()]).then(function(result) {
      vm.horses = result[0];
      vm.allUsers = result[1];
      if (vm.editMode) {
        const contactId = $state.params.id;
        $q.all({
          attachments: getAttachments(contactId),
          contact: rContacts.get({ contactId: $state.params.id }).$promise
        }).then(function(result) {
          backLinkHistory.pushLink(result.contact.name);
          vm.attachments = result.attachments;
          vm.attachmentsReady = true;

          vm.contact = processContact(result.contact);
          vm.horsesAvailableForSelect = vm.horses.filter(horse => filterSelectedHorses(horse));
          vm.loaded = true;
          titleService.setTitle('Edit Contact :: ' + vm.contact.name + ' :: ');

          return result.contact;
        }).then(getAvatar).catch(function(error) {
          vm.error = true;
          vm.loaded = true;
          errorHandler(error);
        });
      } else {
        vm.horsesAvailableForSelect = vm.horses?.filter(horse => filterSelectedHorses(horse));
        backLinkHistory.pushLink('New contact');
        vm.avatarLoaded = true;
        vm.attachmentsReady = true;
        vm.contactMemberships = [{}];

        vm.contact = {
          tenantEnvironmentId: barn.id
        };

        vm.loaded = true;
      }
    });

    vm.selectedHorse = null;
  }

  init();
}
