import {barnManagerModule} from '../../../index.module';

barnManagerModule
  .component('bmUrlWithName', {
    templateUrl: 'url-with-name.html',
    controller: UrlWithName,
    controllerAs: 'vm',
    bindings: {
      name: '@',
      url: '@',
      value:'@'
    }
  });

UrlWithName.$inject = ['SidebarJS'];
function UrlWithName(SidebarJS) {
  const vm = this;
  vm.sidebarName = 'notifications-sidebar';
  vm.navigate = navigate;
  vm.navigateCalendarV2Details = navigateCalendarV2Details

  function navigate() {
    if (SidebarJS.isVisible(vm.sidebarName)) {
      SidebarJS.close(vm.sidebarName);
    }
  }

  function navigateCalendarV2Details(url, event) {
    event.preventDefault();
    if (!url.includes("instance")) {
      return;
    }

    window.location.href = url;
  }

}
