import {Moment} from 'moment';
import {DateConverter} from './date-converter';
import * as moment from 'moment/moment';
import {extendMoment} from 'moment-range';

export const momentRange = extendMoment(moment);
export const today: Moment = moment().startOf('day');

export interface PrimitiveDateRangeMap {
  [name: string]: [Moment, Moment];
}

export interface DateRangeMomentPlain {
  start: Moment;
  end: Moment;
}

export class PrimitiveDateRange {
  constructor(readonly start: Date, readonly end: Date) {
  }

  contains(date: Date): boolean {
    return date >= this.start && date <= this.end;
  }
}

export class DateRange {
  constructor(readonly start: Moment, readonly end: Moment) {
  }

  static today(): DateRange {
    return new DateRange(moment(today), moment(today));
  }

  static yesterday(): DateRange {
    return new DateRange(moment(today).subtract(1, 'days'), moment(today).subtract(1, 'days'));
  }

  static addDays(amount: number, date: Moment = today): DateRange {
    return new DateRange(moment(date).add(amount, 'days'), moment(date).add(amount, 'days'));
  }

  static lastDays(count: number): DateRange {
    return new DateRange(moment(today).subtract(count, 'days'), moment(today).subtract(1, 'days'));
  }

  static in30Days(): DateRange {
    return new DateRange(moment(today), moment(today).add(30, 'days'));
  }

  static singleDate(date: string): DateRange {
    return new DateRange(DateConverter.ISOStringAsMoment(date), DateConverter.ISOStringAsMoment(date));
  }

  static currentMonth(): DateRange {
    return new DateRange(moment(today).startOf('month'), moment(today).endOf('month'));
  }

  static toMonthEnd(): DateRange {
    return new DateRange(moment(today), moment(today).endOf('month'));
  }

  static lastMonth(): DateRange {
    return new DateRange(moment(today).subtract(1, 'month').startOf('month'), moment(today).subtract(1, 'month').endOf('month'));
  }

  static currentYear(): DateRange {
    return new DateRange(moment(today).startOf('year'), moment(today).endOf('year'));
  }

  static lastYear(): DateRange {
    return new DateRange(moment(today).subtract(1, 'year').startOf('year'), moment(today).subtract(1, 'year').endOf('year'));
  }

  static create(startDate: string, endDate: string): DateRange {
    return new DateRange(DateConverter.ISOStringAsMoment(startDate), DateConverter.ISOStringAsMoment(endDate));
  }

  static fromDateRangeMomentPlain(dateRangeMomentPlain: DateRangeMomentPlain) {
    return new DateRange(dateRangeMomentPlain.start, dateRangeMomentPlain.end);
  }

  toPrimitiveDateRange(): PrimitiveDateRange {
    return new PrimitiveDateRange(this.start.toDate(), this.end.toDate());
  }

  toDateRangeMomentPlain(): DateRangeMomentPlain {
    return {
      start: this.start,
      end: this.end
    };
  }

  toArrayDateRangeMoment(): [Moment, Moment] {
    return [this.start, this.end];
  }

  overlaps(range: DateRange): boolean {
    const firstRange = momentRange.range(this.toArrayDateRangeMoment());
    const secondRange = momentRange.range(range.toArrayDateRangeMoment());
    return firstRange.overlaps(secondRange, { adjacent: true });
  }
  static thisQuarter(): DateRange {
    return new DateRange(moment(today).startOf('quarter'), moment(today).endOf('quarter'));
  }

  static lastQuarter(): DateRange {
    return new DateRange(
      moment(today).subtract(1, 'quarter').startOf('quarter'),
      moment(today).subtract(1, 'quarter').endOf('quarter')
    );
  }
}
