import {barnManagerModule} from '../index.module';

barnManagerModule.factory('goNative', goNative);

goNative.$inject = ['$rootScope', 'hasPermission', 'moduleAccessService', 'subscriptionStorage', '$location', 'ng2NgZone', 'userStorage', 'SidebarJS', 'notificationRepository'];
function goNative($rootScope, hasPermission, moduleAccessService, subscriptionStorage, $location, ng2NgZone, userStorage, SidebarJS, notificationRepository) {

  init();

  function init() {
    if (!isGoNative()) {
      return;
    }
    window['goNativeNavigateByUrl'] = (url: string) => {
      if (SidebarJS.isVisible('notifications-sidebar')) {
        SidebarJS.close('notifications-sidebar');
      }
      ng2NgZone.run(() => {
        $location.url(url);
      });
    };

    watchUnreadNotificationsChangedEvent();
  }

  function watchUnreadNotificationsChangedEvent() {
    const unreadNotificationsChanged = $rootScope.$on('unreadNotificationsChanged', function(event, unreadNotifications) {
      window['gonative'].sidebar.getItems().then(items => {
        if (!items?.default) {
          return;
        }
        const sidebarItems = items.default;
        const notificationsItemIndex = sidebarItems.findIndex(item => item.label.startsWith('Notifications'));
        if (notificationsItemIndex !== -1) {
          sidebarItems.splice(notificationsItemIndex, 1, { ...sidebarItems[notificationsItemIndex], label: getUnreadNotificationsLabel(unreadNotifications) });
          setGoNativeSidebarItems(sidebarItems, true, true);
        }
      })
    });
    $rootScope.$on('$destroy', unreadNotificationsChanged);
  }

  function getUnreadNotificationsLabel(unreadNotifications: number) {
    return unreadNotifications && unreadNotifications > 0 ? `Notifications (${unreadNotifications})` : 'Notifications';
  }

  function isGoNative() {
    return navigator.userAgent.indexOf('gonative') > -1;
  }

  function isIOS() {
    return navigator.userAgent.indexOf('GoNativeIOS') > -1;
  }

  function isAndroid() {
    return navigator.userAgent.indexOf('GoNativeAndroid') > -1;
  }

  function setGoNativeSidebarItems(items, enabled, persist) {
    const jsonItems = JSON.stringify(items);
    window.location.href = 'gonative://sidebar/setItems?items=' + encodeURIComponent(jsonItems) + '&enabled=' + enabled + '&persist=' + persist;
  }

  function getBarnGroupMenuItems() {
    const barnGroupItems = [];

    if (moduleAccessService.hasModuleAccess('activity') && hasPermission('whiteboard:read')) {
      barnGroupItems.push({
        label: 'Whiteboard',
        url: 'javascript:window.goNativeNavigateByUrl("/n/activity?section=whiteboard")'
      });
    }

    if (moduleAccessService.hasModuleAccess('activity')) {
      barnGroupItems.push({
        label: 'Activity',
        url: 'javascript:window.goNativeNavigateByUrl("/n/activity?section=timeline")'
      });
    }

    if (moduleAccessService.hasModuleAccess('horses')) {
      barnGroupItems.push({
        label: 'Horses',
        url: 'javascript:window.goNativeNavigateByUrl("/n/horses")'
      });
    }

    if (moduleAccessService.hasModuleAccess('events') && (hasPermission('events:read') || hasPermission('lessons:read'))) {
      barnGroupItems.push({
        label: 'Calendar',
        url: `${window.location.origin}/invoicing/calendar-v2`
      });
    }

    if (moduleAccessService.hasModuleAccess('conversations') && hasPermission('conversations:read')) {
      barnGroupItems.push({
        label: 'Conversation',
        url: 'javascript:window.goNativeNavigateByUrl("/n/conversations")'
      });
    }

    if (moduleAccessService.hasModuleAccess('records') && hasPermission('records:read')) {
      barnGroupItems.push({
        label: 'Records',
        url: 'javascript:window.goNativeNavigateByUrl("/n/records")'
      });
    }
    if (hasPermission('reports:read') && moduleAccessService.hasModuleAccess('reports')) {
      barnGroupItems.push({
        label: 'Reports',
        url: 'javascript:window.goNativeNavigateByUrl("/n/reports")'
      });
    }
    if (moduleAccessService.hasModuleAccess('lists') && hasPermission('lists:read')) {
      barnGroupItems.push({
        label: 'Lists',
        url: 'javascript:window.goNativeNavigateByUrl("/n/lists")'
      });
    }

    if (moduleAccessService.hasModuleAccess('contacts') && hasPermission('contacts:read')) {
      barnGroupItems.push({
        label: 'Contacts',
        url: 'javascript:window.goNativeNavigateByUrl("/n/contacts")'
      });
    }

    if (moduleAccessService.hasModuleAccess('feed') && hasPermission('feed:read')) {
      barnGroupItems.push({
        label: 'Feed',
        url: 'javascript:window.goNativeNavigateByUrl("/n/entries")'
      });
    }

    const currentUser = userStorage.getUser();

    if (currentUser.isInvoicingEnabled && moduleAccessService.hasModuleAccess('invoicing') && hasPermission('invoicing:full')) {
      barnGroupItems.push({
        label: 'Invoicing',
        url: '',
        grouping: '[grouping]',
        isGrouping: true,
        isSubmenu: false,
        subLinks: [...getInvoicingGroupMenuItems()]
      });
    }

    return barnGroupItems;
  }

  function getInvoicingGroupMenuItems() {
    const invoicingOrigin = `${window.location.origin}/invoicing/`;
    return [
      {
        label: 'Dashboard',
        url: invoicingOrigin
      },
      {
        label: 'Activity',
        url: invoicingOrigin + 'activity'
      },
      {
        label: 'Reports',
        url: invoicingOrigin + 'analytics'
      },
      {
        label: 'Invoices',
        url: invoicingOrigin + 'invoices'
      },
      {
        label: 'Recurring Invoices Settings',
        url: invoicingOrigin + 'recurring-invoices-settings'
      },
      {
        label: 'Customers',
        url: invoicingOrigin + 'customers'
      },
      {
        label: 'Products & Services',
        url: invoicingOrigin + 'products-and-services'
      },
      {
        label: 'Quickbooks',
        url: invoicingOrigin + 'quickbooks'
      },
      {
        label: 'Invoice Settings',
        url: invoicingOrigin + 'settings/ach-settings'
      }
    ];
  }

  function getProfileGroupMenuItems() {
    const profileGroupItems = [];

    profileGroupItems.push({
      label: 'My Profile',
      url: 'javascript:window.goNativeNavigateByUrl("/n/profile")'
    });

    if ((hasPermission('admin:full') || hasPermission('vendors:read')) && moduleAccessService.hasModuleAccess('admin')) {
      const hasPeriodEnded = subscriptionStorage.getSubscription().hasPeriodEnded();
      const hasVendorsAccess = moduleAccessService.hasModuleAccess('vendors') && hasPermission('vendors:read');
      profileGroupItems.push(getGoNativeAdminMenu(hasPeriodEnded, hasVendorsAccess));
    }

    profileGroupItems.push({
      label: 'Log Out',
      url: 'javascript:jQuery("a.logout").click()'
    });

    return profileGroupItems;
  }

  function getNotificationMenuItems() {
    const notificationMenuItems = [];
    notificationMenuItems.push({
      label: getUnreadNotificationsLabel(notificationRepository.getUnreadNotifications()),
      url: 'javascript:jQuery("div.bm-notifications-icon").click()'
    });

    return notificationMenuItems;
  }

  function getGoNativeAdminMenu(hasPeriodEnded, hasVendorsAccess) {
    const accountSettingsGroupItems = {
      label: 'Account Settings',
      url: '',
      grouping: '[grouping]',
      isGrouping: true,
      isSubmenu: false,
      subLinks: []
    };

    if (hasPermission('admin:full') && !hasPeriodEnded) {
      accountSettingsGroupItems.subLinks.push(
        {
          label: 'Barn Profile',
          url: 'javascript:window.goNativeNavigateByUrl("/n/admin/barn-profile")'
        },
        {
          label: 'Users & Permissions',
          url: 'javascript:window.goNativeNavigateByUrl("/n/admin/users")'
        }
      );
    }

    if (!hasPeriodEnded) {
      if (hasVendorsAccess) {
        accountSettingsGroupItems.subLinks.push({
          label: 'Vendors',
          url: 'javascript:window.goNativeNavigateByUrl("/n/admin/vendors")'
        });
      }
      if (hasPermission('admin:full')) {
        accountSettingsGroupItems.subLinks.push({
          label: 'Contact Support',
          url: 'javascript:window.goNativeNavigateByUrl("/n/admin/contact-support")'
        });
      }
    }

    return accountSettingsGroupItems;
  }

  function setGoNativeBarnMenu() {
    if (!isGoNative()) {
      return;
    }
    const items = [...getNotificationMenuItems(), ...getBarnGroupMenuItems(), ...getProfileGroupMenuItems()];
    setGoNativeSidebarItems(items, true, true);
  }

  function setGoNativeHorseMenu(horseId) {
    if (!isGoNative()) {
      return;
    }

    window['goNativeNavigateByHorseUrl'] = (url: string) => {
      ng2NgZone.run(() => {
        $location.url(`/n/horse/${horseId}/${url}`);
      });
    };

    const horseGroupItems = [];

    if (moduleAccessService.hasModuleAccess('activity')) {
      horseGroupItems.push({
        label: 'Home',
        url: 'javascript:window.goNativeNavigateByUrl("/n/activity")'
      });
    }

    horseGroupItems.push({
      label: 'Back to Horses',
      url: 'javascript:window.goNativeNavigateByUrl("/n/horses")'
    });


    if (moduleAccessService.hasModuleAccess('activity')) {
      horseGroupItems.push({
        label: 'Activity',
        url: 'javascript:window.goNativeNavigateByHorseUrl("activity")'
      });
    }
    if (hasPermission('events:read') && moduleAccessService.hasModuleAccess('events')) {
      horseGroupItems.push({
        label: 'Events',
        url: 'javascript:window.goNativeNavigateByHorseUrl("events")'
      });
    }
    if (hasPermission('conversations:read') && moduleAccessService.hasModuleAccess('conversations')) {
      horseGroupItems.push({
        label: 'Conversations',
        url: 'javascript:window.goNativeNavigateByHorseUrl("conversations")'
      });
    }
    if (hasPermission('records:read') && moduleAccessService.hasModuleAccess('records')) {
      horseGroupItems.push({
        label: 'Records',
        url: 'javascript:window.goNativeNavigateByHorseUrl("records")'
      });
    }
    if (hasPermission('reports:read') && moduleAccessService.hasModuleAccess('reports')) {
      horseGroupItems.push({
        label: 'Reports',
        url: 'javascript:window.goNativeNavigateByUrl("reports")'
      });
    }
    if (hasPermission('lists:read') && moduleAccessService.hasModuleAccess('lists')) {
      horseGroupItems.push({
        label: 'Lists',
        url: 'javascript:window.goNativeNavigateByHorseUrl("lists")'
      });
    }
    if (hasPermission('feed:read') && moduleAccessService.hasModuleAccess('feed')) {
      horseGroupItems.push({
        label: 'Feed',
        url: 'javascript:window.goNativeNavigateByHorseUrl("entries")'
      });
    }

    const items = [...getNotificationMenuItems(), ...horseGroupItems, ...getProfileGroupMenuItems()];

    setGoNativeSidebarItems(items, true, true);
  }

  function setGoNativeReactivateMenu() {
    if (!isGoNative()) {
      return;
    }
    const items = [...getNotificationMenuItems(), ...getProfileGroupMenuItems()];
    setGoNativeSidebarItems(items, true, true);
  }

  function windowOpen(url) {
    if (isIOS()) {
      window.location.href = 'gonative://window/open?url=' + encodeURIComponent(url);
    } else {
      window.open(url, '_self');
    }
  }

  function downloadFile(url) {
    window.location.href = 'gonative://share/downloadFile?url=' + encodeURIComponent(url);
  }

  function downloadImage(url) {
    window.location.href = 'gonative://share/downloadImage?url=' + encodeURIComponent(url);
  }

  function oneSignalRegister() {
    if (isGoNative()) {
      window.location.href = 'gonative://onesignal/register';
    }
  }

  function oneSignalRegisterAndSetExternalUserId(userUuid) {
    if (isGoNative()) {
      const urls = ['gonative://onesignal/register', `gonative://onesignal/externalUserId/set?externalId=${userUuid}`];
      const json = JSON.stringify({ urls: urls });
      window.location.href = 'gonative://nativebridge/multi?data=' + encodeURIComponent(json);
    }
  }

  function oneSignalRemoveExternalUserId() {
    if (isGoNative()) {
      window['gonative'].onesignal.externalUserId.remove();
    }
  }

  return {
    setGoNativeBarnMenu: setGoNativeBarnMenu,
    setGoNativeHorseMenu: setGoNativeHorseMenu,
    setGoNativeReactivateMenu: setGoNativeReactivateMenu,
    windowOpen: windowOpen,
    isGoNative: isGoNative,
    isIOS: isIOS,
    isAndroid: isAndroid,
    downloadFile: downloadFile,
    downloadImage: downloadImage,
    oneSignalRegister: oneSignalRegister,
    oneSignalRegisterAndSetExternalUserId: oneSignalRegisterAndSetExternalUserId,
    oneSignalRemoveExternalUserId: oneSignalRemoveExternalUserId
  };
}
